import {SiteMetadata} from '../models/SiteMetadata';
import {SEOMetadata} from '../models/SEOMetadata';
import {decodeHtmlCharCodes} from '../../../utils';

const PRODUCT_CAT_SEO_REGEX = /(\d* - product_cat -)/;
const SEO_META_ROBOT_OVERRIDE = process.env.GATSBY_SEO_META_ROBOTS_OVERRIDE;

export function computeSEOTitle(siteMeta: SiteMetadata, yoastMeta: SEOMetadata) {
    return yoastMeta.title ? yoastMeta.title : siteMeta.title;
}

export function computeSEODescription(siteMeta: SiteMetadata, yoastMeta: SEOMetadata) {
    return yoastMeta.metaDesc ? yoastMeta.metaDesc : siteMeta.description;
}

function computeRobotsMeta(yoastMeta: SEOMetadata): any[] {
    const isNoIndex = yoastMeta.metaRobotsNoindex === '1' || yoastMeta.metaRobotsNoindex === 'true';
    const isNoFollow = yoastMeta.metaRobotsNofollow === '1' || yoastMeta.metaRobotsNofollow === 'true';
    const noIndexValue = isNoIndex ? ['noindex'] : [];
    const noFollowValue = isNoFollow ? ['nofollow'] : [];
    const content = SEO_META_ROBOT_OVERRIDE ? SEO_META_ROBOT_OVERRIDE : [...noIndexValue, ...noFollowValue].join(', ');
    return isNoIndex || isNoFollow || SEO_META_ROBOT_OVERRIDE ? [{name: 'robots', content}] : [];
}

export function computeSEOMeta(siteMeta: SiteMetadata, yoastMeta: SEOMetadata, addonMeta: any[] = []) {
    let title = decodeHtmlCharCodes(computeSEOTitle(siteMeta, yoastMeta));
    console.log('title:', title);
    title = title.replace(PRODUCT_CAT_SEO_REGEX, '');
    const description = decodeHtmlCharCodes(computeSEODescription(siteMeta, yoastMeta));
    const keywords =
        yoastMeta.metaKeywords && yoastMeta.metaKeywords.length > 0
            ? [{name: `keywords`, content: yoastMeta.metaKeywords.join(`, `)}]
            : [];
    const robots = computeRobotsMeta(yoastMeta);
    // console.log('Robots meta:', robots, SEO_META_ROBOT_OVERRIDE);
    const metadata = [
        {
            name: `description`,
            content: description,
        },
        {
            property: `og:title`,
            content: yoastMeta.opengraphTitle ? decodeHtmlCharCodes(yoastMeta.opengraphTitle) : title,
        },
        {
            property: `og:description`,
            content: yoastMeta.opengraphDescription ? decodeHtmlCharCodes(yoastMeta.opengraphDescription) : description,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: siteMeta.author,
        },
        {
            name: `twitter:title`,
            content: yoastMeta.twitterTitle ? decodeHtmlCharCodes(yoastMeta.twitterTitle) : title,
        },
        {
            name: `twitter:description`,
            content: yoastMeta.twitterDescription ? decodeHtmlCharCodes(yoastMeta.twitterDescription) : description,
        },
        ...robots,
        ...keywords,
        ...addonMeta,
    ];
    return {title, metadata};
}
