import React, {useState} from 'react';
import {navigate} from 'gatsby';
import Headroom from 'react-headroom';
import Box from '@material-ui/core/Box';
import {Footer} from '../components/Footer';
import {Header} from '../components/Header';
import {HeaderTopBar} from '../components/HeaderTopBar';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {AppSidebar} from '../components/AppSidebar';
import {useHeaderMenus} from '../../common/hooks/useHeaderMenus';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {AgeDialog} from '../components/AgeDialog';
import {useCustomer} from '../../common/hooks/useCustomer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
    }),
);

interface Props {
    width: Breakpoint;
    location: Location;
    children: any;
    contentStyle?: any;
    noMargin?: boolean;
}

const _Layout: React.FC<Props> = ({children, contentStyle, noMargin, width}) => {
    const classes = useStyles();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const headerMenus = useHeaderMenus();
    const [blockSite, setBlockSite] = useState(false);
    const {customerData, acceptAgeGate} = useCustomer();

    const handleOpenSidebar = () => {
        setSidebarOpen(true);
    };
    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    const handleAgeAgreed = (accepted: boolean) => {
        acceptAgeGate(accepted);
        // customer.customerData.acceptedAgeGate = accepted;
        // customer.updateCustomer(customer);
        // console.log('Updating acceptAgeGate:', accepted);
        setBlockSite(!accepted);
    };

    const isMobile = isWidthDown('sm', width);
    const sidebarEl = isMobile ? (
        <AppSidebar open={sidebarOpen} menus={headerMenus} onClose={handleSidebarClose} />
    ) : null;

    if (blockSite) {
        navigate('/blocked/');
        return null;
    }

    const showAgeGate = customerData.initComplete && !customerData.acceptedAgeGate;
    return (
        <>
            <HeaderTopBar />
            <AgeDialog open={showAgeGate} onAgeAgreed={handleAgeAgreed} />
            <Headroom upTolerance={10} downTolerance={10} style={{zIndex: 1100}}>
                <Header mobile={isMobile} onOpenSidebar={handleOpenSidebar} />
            </Headroom>
            {sidebarEl}
            <Box className={noMargin ? '' : classes.content} style={contentStyle}>
                {children}
            </Box>
            <Footer />
        </>
    );
};

_Layout.defaultProps = {
    noMargin: false,
};

export const Layout = withWidth()(_Layout);
