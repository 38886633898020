import {Menu} from '../models/Menu';
import {MenuItem} from '../models/MenuItem';

export function mapMenuItem(item: any): MenuItem {
    const children =
        item.childItems && item.childItems.edges
            ? item.childItems.edges.map((edge: any) => mapMenuItem(edge.node))
            : undefined;
    return {
        id: item.id,
        menuItemId: item.menuItemId,
        description: item.description,
        children,
        label: item.label,
        target: item.target,
        url: item.url,
        connectedObject: item.connectedObject,
    };
}

export function mapMenu(rawMenu: any): Menu {
    const items =
        rawMenu.menuItems && rawMenu.menuItems.edges
            ? rawMenu.menuItems.edges.map((edge: any) => mapMenuItem(edge.node))
            : undefined;
    return {
        id: rawMenu.id,
        slug: rawMenu.slug,
        name: rawMenu.name,
        title: rawMenu.menuDetail && rawMenu.menuDetail.menuTitle,
        position: rawMenu.menuDetail && rawMenu.menuDetail.menuPosition,
        items,
    };
}
