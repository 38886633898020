import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {Menu} from '../../core/models/Menu';
import {HeaderAppBarMenuItem} from './HeaderAppBarMenuItem';
import {MenuItem} from '../../core/models/MenuItem';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginLeft: theme.spacing(1),
            textTransform: 'none',
            alignSelf: 'center',
        },
    }),
);

interface Props {
    menus: Menu[];
}

export const HeaderAppBarMenu: React.FC<Props> = ({menus}) => {
    const classes = useStyles();
    const allItemsEL = [];
    for (const menu of menus) {
        const menuItemsEL =
            menu && menu.items
                ? menu.items.map((item: MenuItem) => (
                      <HeaderAppBarMenuItem className={classes.button} key={item.id} item={item} />
                  ))
                : [];
        allItemsEL.push(...menuItemsEL);
    }
    return <>{allItemsEL}</>;
};
