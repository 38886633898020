import React, {useState} from 'react';
import {AppBarButton} from './AppBarButton';
import {MenuItem} from '../../core/models/MenuItem';
import {computeMenuItemUrl, hasMenuSubItems} from '../../core/util/MenuUtil';
import {HeaderAppBarSubMenu} from './HeaderAppBarSubMenu';
import {NavLink} from '../../uxui/NavLink';
import {AppBarButtonExpandable} from './AppBarButtonExpandable';

interface Props {
    className: string;
    item: MenuItem;
}

export const HeaderAppBarMenuItem: React.FC<Props> = ({className, item}) => {
    const hasSubMenu = hasMenuSubItems(item);
    const [anchorEl, setAnchorEl] = useState(null);

    const onButtonClick = (ev: any) => (hasSubMenu ? setAnchorEl(ev.currentTarget as any) : undefined);
    const onClose = () => setAnchorEl(null);

    const menuUrl = computeMenuItemUrl(item);
    const subMenuEl = hasSubMenu ? <HeaderAppBarSubMenu anchorEl={anchorEl} parent={item} onClose={onClose} /> : null;
    const button = hasSubMenu ? (
        <AppBarButtonExpandable color="inherit" className={className} onClick={onButtonClick} open={!!anchorEl}>
            {item.label}
        </AppBarButtonExpandable>
    ) : (
        <AppBarButton color="inherit" className={className} onClick={onButtonClick}>
            {item.label}
        </AppBarButton>
    );
    const itemEl = hasSubMenu ? button : <NavLink to={menuUrl}>{button}</NavLink>;
    return (
        <>
            {itemEl}
            {subMenuEl}
        </>
    );
};
