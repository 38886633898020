import React from 'react';
import Badge from '@material-ui/core/Badge/Badge';
import ShoppingCart from '@material-ui/icons/ShoppingBasket';
import IconButton from '@material-ui/core/IconButton';

interface Props {
    count?: number;
    ariaLabel?: string;
}

export const ShoppingCartSummaryButton: React.FC<Props> = ({ariaLabel, count}) => (
    <IconButton aria-label={ariaLabel} color="inherit">
        <Badge badgeContent={count && count > 0 ? count : undefined} color="secondary">
            <ShoppingCart />
        </Badge>
    </IconButton>
);
