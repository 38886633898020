import React from 'react';
import {MenuItem} from '../../core/models/MenuItem';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {NavLink} from '../../uxui/NavLink';
import {convertSourceUrlToLocal} from '../../core/util/UrlUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            color: theme.palette.common.black,
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
    }),
);

interface Props {
    item: MenuItem;
}

export const FooterMenuItem: React.FC<Props> = ({item}) => {
    const classes = useStyles();
    const menuUrl = convertSourceUrlToLocal(item.url);
    return (
        <NavLink className={classes.item} key={item.id} to={menuUrl}>
            <Typography variant="body1">{item.label}</Typography>
        </NavLink>
    );
};
