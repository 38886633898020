import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Logo} from './Logo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 400,
            [theme.breakpoints.down('md')]: {
                minWidth: '80%',
            },
        },
        title: {
            textAlign: 'center',
        },
        logo: {
            height: 100,
        },
    }),
);

interface Props {
    open: boolean;
    onAgeAgreed: any;
}

export const AgeDialog: React.FC<Props> = ({open, onAgeAgreed}) => {
    const classes = useStyles();
    const onButtonClickYes = () => {
        onAgeAgreed(true);
    };

    const onButtonClickNo = () => {
        onAgeAgreed(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onButtonClickNo}
            aria-labelledby="age-dialog-title"
            aria-describedby="age-dialog-description"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            classes={{
                paper: classes.root,
            }}
        >
            <DialogTitle className={classes.title} id="age-dialog-title">
                <Box>
                    <Logo className={classes.logo} />
                </Box>
                Are you 21 or over?
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant={'h6'} component={'p'} id="age-dialog-description">
                    If so, come on in.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onButtonClickNo} color="secondary">
                    No
                </Button>
                <Button onClick={onButtonClickYes} color="primary" variant={'outlined'}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
