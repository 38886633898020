import React from 'react';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import {MenuItem} from '../../core/models/MenuItem';
import {computeMenuItemUrl} from '../../core/util/MenuUtil';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {NavLink} from '../../uxui/NavLink';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subitem: {
            color: theme.palette.common.black,
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
    }),
);

interface Props {
    anchorEl: any;
    parent: MenuItem;
    onClose: () => void;
}

export const HeaderAppBarSubMenu: React.FC<Props> = ({anchorEl, parent, onClose}) => {
    const classes = useStyles();
    const subItemsEl = parent.children
        ? parent.children.map((subItem: MenuItem) => (
              <NavLink key={subItem.id} to={computeMenuItemUrl(subItem)} className={classes.subitem}>
                  <MuiMenuItem onClick={onClose}>{subItem.label}</MuiMenuItem>
              </NavLink>
          ))
        : null;
    const id = `${parent.id}`;
    return (
        <MuiMenu
            id={id}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            transformOrigin={{vertical: 'top', horizontal: 'center'}}
        >
            {subItemsEl}
        </MuiMenu>
    );
};
