import React from 'react';
import classNames from 'classnames';
import {createStyles, fade, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Colors} from '../../../theme';
import {HeaderAppBarMenu} from './HeaderAppBarMenu';
import {useHeaderMenus} from '../../common/hooks/useHeaderMenus';
import {ShoppingCartSummaryButton} from './ShoppingCartSummaryButton';
import {NavLink} from '../../uxui/NavLink';
import {useLocalCartCount} from '../../common/hooks/useLocalCartCount';
import {Logo} from './Logo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appbar: {
            backgroundColor: Colors.WHITE,
            color: Colors.BLACK,
        },
        grow: {
            flexGrow: 1,
        },
        button: {
            marginLeft: theme.spacing(1),
            textTransform: 'none',
            alignSelf: 'center',
            // color: theme.palette.primary.contrastText,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        megaMenuButton: {
            alignSelf: 'stretch',
            display: 'flex',
        },
        title: {
            display: 'block',
            textAlign: 'center',
            textTransform: 'uppercase',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            height: '100%',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }),
);

interface Props {
    mobile: boolean;
    onOpenSidebar?: () => void;
}

export const Header: React.FC<Props> = ({mobile, onOpenSidebar}) => {
    const classes = useStyles();
    const {cartCount} = useLocalCartCount();
    // const onSearchChange = (value: string) => console.log('Search changed:', value);

    // const popupState = usePopupState({variant: 'popover', popupId: 'megaMenu'});
    // const megaMenuData: Menu[] = [
    //     {
    //         id: 'byEffectMenu',
    //         title: 'By Effect',
    //         items: [
    //             {title: 'Hydrating', to: '/collections/hydrating'},
    //             {title: 'Anti-aging', to: '/collections/anti-aging'},
    //             {title: 'Restore Overnight', to: '/collections/restore-overnight'},
    //         ],
    //     },
    //     {
    //         id: 'byBrandMenu',
    //         title: 'By Brand',
    //         items: [
    //             {title: 'Karibo', to: '/collections/karibo'},
    //             {title: "Kat's Natural", to: '/collections/kats-natural'},
    //             {title: 'Life Elements', to: '/collections/life-elements'},
    //             {title: 'Palmettos', to: '/collections/palmettos-harmony'},
    //         ],
    //     },
    // ];

    // console.log('popupState:', popupState);

    /*
    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="open drawer">
        <MenuIcon />
    </IconButton>
     */
    /*
    <HeaderMegaMenu {...bindMenu(popupState)} menues={megaMenuData} />
     */
    const topMenus = useHeaderMenus();
    const desktopMode = (
        <>
            <HeaderAppBarMenu menus={topMenus} />
            <Logo className={classNames(classes.title, classes.grow)} />
            <div className={classes.grow}></div>
            {/*<SearchInput onSearchChange={onSearchChange} className={classes.search} />*/}
            <div className={classes.sectionDesktop}>
                <NavLink to={'/cart'}>
                    <ShoppingCartSummaryButton count={cartCount} ariaLabel={'Cart Items'} />
                </NavLink>
            </div>
        </>
    );
    const mobileMode = (
        <>
            <IconButton color="inherit" onClick={onOpenSidebar}>
                <MenuIcon />
            </IconButton>
            <Logo className={classNames(classes.title, classes.grow)} />
            <div className={classes.grow}></div>
            <NavLink to={'/cart'}>
                <ShoppingCartSummaryButton count={cartCount} ariaLabel={'Cart Items'} />
            </NavLink>
        </>
    );

    return (
        <div className={classes.grow}>
            <AppBar className={classes.appbar} color="default" position="static">
                <Toolbar>{mobile ? mobileMode : desktopMode}</Toolbar>
            </AppBar>
        </div>
    );
};
