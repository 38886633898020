import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

interface Props {
    text: string;
}

export const FooterDisclaimer: React.FC<Props> = ({text}) => {
    return (
        <Box>
            <Typography variant="body2">{text}</Typography>
        </Box>
    );
};
