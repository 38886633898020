import {graphql, useStaticQuery} from 'gatsby';
import {mapMenu} from '../../core/mapper/MenuMapper';

export function useWPMenus() {
    const {wpgraphql} = useStaticQuery(graphql`
        query {
            wpgraphql {
                menus {
                    edges {
                        node {
                            id
                            menuId
                            name
                            slug
                            count
                            menuItems {
                                edges {
                                    node {
                                        ...MenuItemInfoFragment
                                    }
                                }
                            }
                            ...MenuInfoAcfFragment
                        }
                    }
                }
            }
        }
    `);
    const menuItems = wpgraphql.menus.edges.map((edge: any) => mapMenu(edge.node));
    return menuItems;
}
