import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {Menu} from '../../core/models/Menu';
import {AppSidebarMenu} from './AppSidebarMenu';
import {Spacer} from '../../uxui/Spacer';
import {FooterCopyrightBar} from './FooterCopyrightBar';
import {MenuItem} from '../../core/models/MenuItem';

const maxDrawerWidth = 250;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            height: '100%',
            maxWidth: maxDrawerWidth,
            position: 'relative',
            padding: 0,
        },
        spacer: {
            flex: 1,
        },
        copyright: {
            padding: theme.spacing(1),
        },
    }),
);

interface Props {
    open: boolean;
    onClose: () => void;
    menus: Menu[];
}

export const AppSidebar: React.FC<Props> = ({menus, open, onClose}) => {
    const classes = useStyles();
    // FIXME - Pending to handle manual navigation
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleMenuItemClick = (_item: MenuItem, _navigate: boolean) => {
        // console.log('menuItemClicked:', item);
        if (onClose) onClose();
        // Navigate here;
    };
    const menusEl = menus.map(item => <AppSidebarMenu key={item.id} menu={item} onItemClick={handleMenuItemClick} />);
    return (
        <Drawer open={open} onClose={onClose} className="app-sidebar" classes={{paper: classes.paper}}>
            <List>{menusEl}</List>
            <Spacer />
            <FooterCopyrightBar className={classes.copyright} text="@2019 Puravida Skincare" />
        </Drawer>
    );
};
