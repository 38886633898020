import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';
import {CartItemSummaryFragmentCS} from '../../core/fragments/apollo/CartItemSummaryFragmentCS';
import {CartRemote} from '../../core/models/CartRemote';

const FETCH_LOCAL_CART_SUMMARY = gql`
    query fetchLocalCartCount {
        cart @client {
            contents {
                edges {
                    node {
                        ...CartItemSummaryFragmentCS
                    }
                }
            }
        }
    }
    ${CartItemSummaryFragmentCS}
`;

export function useLocalCartCount() {
    try {
        const {data} = useQuery(FETCH_LOCAL_CART_SUMMARY);
        if (!data) {
            return {cartCount: undefined};
        }
        const cart: CartRemote = data.cart;
        const itemCount = cart && cart.contents.edges.reduce((acc, item) => acc + item.node.quantity, 0);
        return {cartCount: itemCount};
    } catch (err) {
        return {cartCount: undefined};
    }
}
