import gql from 'graphql-tag';

export const CartItemSummaryFragmentCS = gql`
    fragment CartItemSummaryFragmentCS on CartItem {
        key
        quantity
        subtotal
        subtotalTax
        tax
        total
    }
`;
