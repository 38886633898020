import React from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {Colors} from '../../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1),
            paddingLeft: 0,
            paddingRight: 0,
            borderTop: `1px solid ${Colors.GRAY}`,
        },
    }),
);

interface Props {
    text: string;
    className?: string;
}

export const FooterCopyrightBar: React.FC<Props> = ({className, text}) => {
    const classes = useStyles();
    return (
        <Box className={classNames(classes.bar, className)}>
            <Typography variant="body2">{text}</Typography>
        </Box>
    );
};
