import {graphql, useStaticQuery} from 'gatsby';
import _get from 'lodash/get';

interface FooterDetail {
    copyrightText: string;
    disclaimerText: string;
}

export function useFooterDetail(): FooterDetail {
    const {wpgraphql} = useStaticQuery(graphql`
        query {
            wpgraphql {
                pages(where: {name: "home"}) {
                    edges {
                        node {
                            footerDetail {
                                copyrightText
                                disclaimerText
                            }
                        }
                    }
                }
            }
        }
    `);
    const footerDetail = _get(wpgraphql, 'pages.edges[0].node.footerDetail');
    return footerDetail;
}
