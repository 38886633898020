import React from 'react';
import {FooterSection} from './FooterSection';
import {Menu} from '../../core/models/Menu';
import {MenuItem} from '../../core/models/MenuItem';
import {FooterMenuItem} from './FooterMenuItem';

interface Props {
    menu: Menu;
}

export const FooterMenuSection: React.FC<Props> = ({menu}) => {
    const filteredItems =
        menu && menu.items ? menu.items.filter((item: MenuItem) => !item.children || item.children.length === 0) : [];
    const menuItemsEL = filteredItems.map((item: MenuItem) => <FooterMenuItem key={item.id} item={item} />);
    const title = menu.title ? menu.title : menu.name;
    return <FooterSection title={title}>{menuItemsEL}</FooterSection>;
};
