import React from 'react';
import Typography from '@material-ui/core/Typography';

interface Props {
    title: string;
    children?: any;
}

export const FooterSection: React.FC<Props> = props => {
    const {title, children} = props;
    return (
        <>
            <Typography variant="h6">{title}</Typography>
            {children}
        </>
    );
};
