/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {Helmet} from 'react-helmet';
import {withPrefix} from 'gatsby';

interface Props {
    lang?: string;
    meta?: any[];
    title: string;
}

const SEO: React.FC<Props> = ({lang, meta, title}) => {
    const htmlAttrs = {lang};
    const tmpMeta = meta ? meta : [];

    return (
        <Helmet
            htmlAttributes={htmlAttrs as any}
            title={title}
            meta={[
                ...tmpMeta,
                {name: 'msapplication-TileColor', content: '#da532c'},
                {name: 'theme-color', content: '#ffffff'},
            ]}
        >
            <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('/favicons/apple-touch-icon.png')} />
            <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('/favicons/favicon-32x32.png')} />
            <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('/favicons/favicon-16x16.png')} />
        </Helmet>
    );
};

SEO.defaultProps = {
    lang: `en`,
    meta: [],
};

export default SEO;
