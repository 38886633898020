import {ImageData} from '../models/ImageData';

export function mapLocalSharpImageToImageData(childImageSharp: any, altText: string): ImageData {
    return {
        imageFile: {
            ...childImageSharp,
        },
        src: '',
        altText,
        resizeMode: '',
    };
}
