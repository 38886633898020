import React from 'react';
import {ButtonProps} from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {AppBarButton} from './AppBarButton';

interface Props extends ButtonProps {
    open: boolean;
}

export const AppBarButtonExpandable: React.FC<Props> = ({open, children, ...other}) => {
    const Icon = open ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    return (
        <AppBarButton {...other} endIcon={Icon}>
            {children}
        </AppBarButton>
    );
};

AppBarButtonExpandable.defaultProps = {
    open: false,
};
