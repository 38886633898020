import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import IconButton from '@material-ui/core/IconButton';
import {Colors} from '../../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: Colors.LIGHT_GRAY,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: theme.spacing(1),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        },
        button: {
            color: Colors.BLACK,
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        spacer: {
            flexGrow: 1,
        },
        link: {
            textDecoration: 'none',
        },
    }),
);

export const HeaderTopBar: React.FC = () => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Typography variant="body2">Free shipping on all US orders over $75</Typography>
            <div className={classes.spacer} />
            <IconButton size="small" aria-label="show 4 new mails" color="inherit">
                <Instagram />
            </IconButton>
            <IconButton size="small" aria-label="show 4 new mails" color="inherit">
                <Facebook />
            </IconButton>
            <Typography className={classes.button} variant="body2">
                <a className={classes.link} href="mailto: contact@glowymary.com" style={{color: 'inherit'}}>
                    <span>contact@glowymary.com</span>
                </a>
                {/*<NavLink to="/contact" style={{color: 'inherit'}}>*/}
                {/*    <span>Contact Us</span>*/}
                {/*</NavLink>*/}
            </Typography>
        </Box>
    );
};
