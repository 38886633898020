import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {MenuItem} from '../../core/models/MenuItem';
import {NavLink} from '../../uxui/NavLink';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {computeMenuItemUrl, hasMenuSubItems} from '../../core/util/MenuUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            color: theme.palette.common.black,
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        subList: {
            paddingLeft: theme.spacing(2),
        },
    }),
);

interface Props {
    item: MenuItem;
    dense?: boolean;
    disablePadding?: boolean;
    onClick?: (item: MenuItem, navigate: boolean) => void;
}

export const AppSidebarMenuItem: React.FC<Props> = ({item, onClick, ...other}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const menuUrl = computeMenuItemUrl(item);
    const hasSubItems = hasMenuSubItems(item);

    const onItemClick = () => onClick && onClick(item, true);

    const Wrapper = ({children, ...other}: any) => {
        return hasSubItems ? (
            <Box {...other}>{children}</Box>
        ) : (
            <NavLink to={menuUrl} {...other}>
                {children}
            </NavLink>
        );
    };

    const onExpandClick = () => {
        setOpen(!open);
    };
    const subItemsEl =
        item.children && item.children.length > 0
            ? item.children.map(item => (
                  <List className={classes.subList} dense disablePadding key={item.id}>
                      <AppSidebarMenuItem dense item={item} />
                  </List>
              ))
            : null;

    const secondaryAction = hasSubItems ? (
        <IconButton onClick={onExpandClick}>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
    ) : null;
    return (
        <Wrapper>
            <ListItem button={true} className={classes.item} {...other} onClick={onItemClick}>
                <ListItemText primary={item.label}></ListItemText>
                <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
            </ListItem>
            {open ? subItemsEl : null}
        </Wrapper>
    );
};
