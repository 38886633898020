import React from 'react';
import Img from 'gatsby-image';
import Box from '@material-ui/core/Box';
import {graphql, useStaticQuery} from 'gatsby';
import {mapLocalSharpImageToImageData} from '../../core/util/ImageUtil';

export const FooterTrustBadge: React.FC = () => {
    const {imageTrustStamp} = useStaticQuery(graphql`
        query TrustStamp {
            imageTrustStamp: file(relativePath: {eq: "visa-mastercard-trust-badge.png"}) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    const trustBadgeImg = mapLocalSharpImageToImageData(imageTrustStamp, 'Visa / MasterCard');
    return (
        <Box maxHeight={50}>
            {trustBadgeImg && trustBadgeImg.imageFile && (
                <Img fixed={trustBadgeImg.imageFile.childImageSharp.fixed} alt={trustBadgeImg.altText} />
            )}
        </Box>
    );
};
