import React from 'react';
import logo from '../../../images/gm_logo.svg';
import {useSiteMetadata} from '../../common/hooks/useSiteMetadata';

interface Props {
    className?: any;
}

export const Logo: React.FC<Props> = ({className}) => {
    const {title} = useSiteMetadata();
    return <img className={className} src={logo} alt={title} />;
};
