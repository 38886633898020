import {MenuItem} from '../models/MenuItem';
import {Menu} from '../models/Menu';
import {convertSourceUrlToLocal} from './UrlUtil';
import {MenuPosition} from '../constants/MenuPosition';

export function computeMenuItemUrl(item: MenuItem) {
    const obj = item.connectedObject;
    if (!obj) return '';
    if (obj.postId) {
        return `/blog/${obj.slug}`;
    }
    return convertSourceUrlToLocal(item.url);
}

export function hasMenuSubItems(item: MenuItem) {
    return item.children && item.children.length > 0;
}

export function isMenuTopBar(menu: Menu) {
    return menu.position && menu.position.includes(MenuPosition.TOP_BAR);
}

export function isMenuFooter(menu: Menu) {
    return menu.position && menu.position.includes(MenuPosition.FOOTER);
}

export function isPageMenuItem(item: MenuItem) {
    return item.connectedObject && item.connectedObject.pageId;
}

export function isBlogPostMenuItem(item: MenuItem) {
    return item.connectedObject && item.connectedObject.postId;
}

export function isProductCategoryMenuItem(item: MenuItem) {
    return item.connectedObject && item.connectedObject.productCategoryId;
}
