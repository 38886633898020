import {graphql, useStaticQuery} from 'gatsby';
import _get from 'lodash/get';
import {SiteMetadata} from '../../core/models/SiteMetadata';

export function useSiteMetadata(): SiteMetadata {
    const data = useStaticQuery(graphql`
        query {
            wpgraphql {
                allSettings {
                    generalSettingsDescription
                    generalSettingsTitle
                    generalSettingsTimezone
                    generalSettingsTimeFormat
                    generalSettingsLanguage
                    generalSettingsUrl
                }
            }
            site {
                siteMetadata {
                    title
                    description
                    author
                    siteUrl
                }
            }
        }
    `);
    const wpMetadata: any = _get(data, 'wpgraphql.allSettings');
    const localMetadata: any = _get(data, 'site.siteMetadata');
    return {
        title: wpMetadata.generalSettingsTitle ? wpMetadata.generalSettingsTitle : localMetadata.title,
        description: wpMetadata.generalSettingsDescription
            ? wpMetadata.generalSettingsDescription
            : localMetadata.description,
        author: localMetadata.author,
        siteUrl: localMetadata.siteUrl,
    };
}
