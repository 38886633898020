import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Colors} from '../../../theme';
import {FooterCopyrightBar} from './FooterCopyrightBar';
import {useFooterMenus} from '../../common/hooks/useFooterMenus';
import {FooterMenuSection} from './FooterMenuSection';
import {Menu} from '../../core/models/Menu';
import {FooterDisclaimer} from './FooterDisclaimer';
import {FooterTrustBadge} from './FooterTrustBadge';
import {Logo} from './Logo';
import {useFooterDetail} from '../../common/hooks/useFooterDetail';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            flexGrow: 1,
            padding: theme.spacing(6),
            borderTop: `1px solid ${Colors.GRAY}`,
        },
        logoWrapper: {},
        logo: {
            width: '100%',
            padding: theme.spacing(0, 2),
        },
    }),
);

// const textDisclaimer =
//     "Products sold on this website contain less than 0.3% of THC. Products sold on this website are not for use by or sale to persons under the age of 21. Products sold on this website should be used only as directed on the label. They should not be used if you are pregnant or nursing. Consult with a physician before use if you have a serious medical condition or use prescription medications. A Doctor's advice should be sought before using these and any supplemental dietary product. All trademarks and copyrights are property of their respective owners and are not affiliated with nor do they endorse these products. These statements have not been evaluated by the FDA. Products sold on this website are not intended to diagnose, treat, cure or prevent any disease. By using this site, you agree to follow the Privacy Policy and all Terms & Conditions printed on this site. Void Where Prohibited by Law.";

export const Footer: React.FC<any> = () => {
    const classes = useStyles();

    const footerDetail = useFooterDetail();
    const footerMenus = useFooterMenus();
    const footerMenusEl = footerMenus.map((menu: Menu) => (
        <Grid key={menu.id} item md={3} xs={12}>
            <FooterMenuSection menu={menu} />
        </Grid>
    ));

    return (
        <Box className={classes.container}>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid className={classes.logoWrapper} item md={3}>
                        <Logo className={classes.logo} />
                    </Grid>
                    {footerMenusEl}
                </Grid>
                {footerDetail && footerDetail.copyrightText && <FooterCopyrightBar text={footerDetail.copyrightText} />}
                {footerDetail && footerDetail.disclaimerText && <FooterDisclaimer text={footerDetail.disclaimerText} />}
                <FooterTrustBadge />
            </Container>
        </Box>
    );
};
