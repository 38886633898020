import React from 'react';
import {Menu} from '../../core/models/Menu';
import {AppSidebarMenuItem} from './AppSidebarMenuItem';
import {MenuItem} from '../../core/models/MenuItem';

interface Props {
    menu: Menu;
    onItemClick: (item: MenuItem, navigate: boolean) => void;
}

export const AppSidebarMenu: React.FC<Props> = ({menu, onItemClick}) => {
    const menuItemsEl = menu.items.map(item => <AppSidebarMenuItem key={item.id} item={item} onClick={onItemClick} />);
    return <>{menuItemsEl}</>;
};
