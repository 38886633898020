import Button from '@material-ui/core/Button';
import {styled} from '@material-ui/core';

export const AppBarButton = styled(Button)(({theme}) => ({
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    borderRadius: 0,
}));

// AppBarButton.defaultProps = {
//
// };
